<template>
  <v-app>
    <v-card flat>
      <v-card-text>
        <v-card-text>
          <v-tabs vertical class="justify-start">
            <v-tab class="justify-start" v-if="available_gateways.includes('esewa')">
              Esewa
            </v-tab>
            <v-tab class="justify-start" v-if="available_gateways.includes('khalti')">
              Khalti
            </v-tab>
            <v-tab class="justify-start" v-if="available_gateways.includes('connect_ips')">
              Connect IPS
            </v-tab>
            <v-tab class="justify-start" v-if="available_gateways.includes('offline')">
              Offline Payment
            </v-tab>

            <v-tab-item v-if="available_gateways.includes('esewa')">
              <v-card>
                <v-card-text>
                  <div class="col-12 ">
                    <h3>Esewa</h3>
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="esewa.merchant_id" label="Merchant Id" outlined
                                    dense></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-switch
                          v-model="esewa.is_active"
                          label="Status"
                      >Status
                      </v-switch>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      class="text-white float-right btn btn-primary"
                      depressed
                      @click="createOrUpdate(esewa, 'esewa')"
                      :loading="isBusy"
                  >Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item v-if="available_gateways.includes('khalti')">
              <v-card>
                <v-card-text>
                  <div class="col-12 ">
                    <h3>Khalti</h3>
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="khalti.public_key" label="Public Key" outlined
                                    dense></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="khalti.private_key" label="Private Key" outlined
                                    dense></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-switch
                          v-model="khalti.is_active"
                          label="Status"
                      >Status
                      </v-switch>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      class="text-white float-right btn btn-primary"
                      depressed
                      @click="createOrUpdate(khalti, 'khalti')"
                      :loading="isBusy"
                  >Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item v-if="available_gateways.includes('connect_ips')">
              <v-card>
                <v-card-text>
                  <div class="col-12 ">
                    <h3>Connect IPS</h3>
                  </div>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="connect_ips.app_id" label="App Id" outlined dense></v-text-field>

                    </v-col>

                    <v-col cols="12">
                      <v-text-field v-model="connect_ips.app_name" label="App Name" outlined dense></v-text-field>


                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="connect_ips.user_name" label="Username" outlined
                                    dense></v-text-field>

                    </v-col>
                    <v-col cols="12">
                      <v-text-field type="password" v-model="connect_ips.password" label="Password" outlined
                                    dense></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field type="password" v-model="connect_ips.pfx_password"
                                    label="Certificate Password" outlined
                                    dense></v-text-field>
                    </v-col>

                    <v-col cols="12">
                      <v-switch
                          v-model="connect_ips.is_active"
                          label="Status"
                      >Status
                      </v-switch>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      class="text-white float-right btn btn-primary"
                      depressed
                      @click="createOrUpdate(connect_ips,'connect_ips')"
                      :loading="isBusy"
                  >Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>

            <v-tab-item v-if="available_gateways.includes('offline')">
              <v-card flat>
                <v-card-text>
                  <div class="col-12">
                    <h3>Offline payment instruction</h3>
                    <p>This will be displayed as payment instruction for offline payment.</p>
                  </div>
                  <v-col cols="12">
                    <v-file-input
                        v-model="offline.file"
                        prepend-icon=""
                        prepend-inner-icon="mdi-file"
                        outlined
                        dense
                    >
                      <template v-slot:label>
                        Bank QR code <span class="text-danger">*</span>
                      </template>
                    </v-file-input>
                  </v-col>

                  <v-col cols="12" v-if="offline.bank_qr_code_path && offline.bank_qr_code_path.real">
                    <v-img
                        class="cursor-pointer"
                        :src="offline.bank_qr_code_path.real"
                        max-width="150px"
                        max-height="150px"
                    >

                    </v-img>
                  </v-col>
                  <v-col cols="12" xl="12" md="12">
                    <v-text-field v-model="offline.title" label="Payment method title" outlined dense></v-text-field>
                  </v-col>
                  <v-col cols="12" xl="12" md="12">
                    <label>Payment method instruction</label>
                    <ckeditor
                        :config="editorConfig"
                        v-model="offline.description"
                    >
                    </ckeditor>
                  </v-col>
                  <v-col cols="12">
                    <v-switch
                        v-model="offline.is_active"
                        label="Status"
                    >Status
                    </v-switch>
                  </v-col>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      class="text-white float-right btn btn-primary"
                      depressed
                      @click="createOrUpdate(offline, 'offline')"
                      :loading="isBusy"
                  >Save
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card-text>

      </v-card-text>

    </v-card>
    <v-dialog
        v-model="dialog" max-width="600">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col  v-if="offline.bank_qr_code_path">
              <v-img
                  :src="offline.bank_qr_code_path.real"
              >
              </v-img>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import PaymentGatewaySettingService from "@/services/cms/site-setting/payment-gateway/PaymentGatewaySettingService";
import OptionService from "@/services/cms/setting/option/OptionService";

const paymentGatewayService = new PaymentGatewaySettingService();

export default {
  name: "PaymentGateway",
  data() {
    return {
      isBusy: false,
      dialog: false,
      payment_gateways: [],
      available_gateways: ['offline'],
      esewa: {},
      khalti: {},
      connect_ips: {},
      offline: {},
      editorConfig: {
        versionCheck: false,
        toolbar: [
          [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' , 'Source','-','Save','NewPage','DocProps','Preview','Print','-','Templates'],
        ]
      }
    }
  },
  mounted() {
    this.getAllPaymentGateway()
  },
  methods: {
    closeDialog() {
      this.dialog = false
      this.isBusy = false
      this.getAllPaymentGateway()
    },
    getAllPaymentGateway() {
      paymentGatewayService.all().then(response => {
        this.payment_gateways = response.data
        this.payment_gateways.forEach(ele => {
          switch (ele.type) {
            case 'esewa':
              this.esewa = ele
              break;
            case 'khalti':
              this.khalti = ele
              break;
            case 'connect_ips':
              this.connect_ips = ele
              break;
            case 'offline':
              this.offline = ele
              break;
          }
        })
      })
    },
    createOrUpdate(payment_gateway, type) {
      if (payment_gateway.id)
        this.update(payment_gateway);
      else {
        payment_gateway.type = type
        this.create(payment_gateway);
      }
    },
    create(payment_gateway) {
      this.isBusy = true
      paymentGatewayService.store(payment_gateway).then(response => {
        this.$snotify.success("Payment Gateway cred")
        this.getAll()
      }).catch(error => {
        // console.log(error)
      }).finally(this.isBusy = false);
    },
    convertFormData(payment_gateway) {
      let formData = new FormData();
      for (let key in payment_gateway) {
        if (key === "file" && payment_gateway[key] != null && payment_gateway[key] != undefined) {
          formData.append('file', payment_gateway[key]);
        }else {
          if(payment_gateway[key]){
            formData.append(key, payment_gateway[key]);
          }
        }
      }
      return formData;
    },
    update(payment_gateway) {
      this.isBusy = true
      let form = this.convertFormData(payment_gateway);
      paymentGatewayService.update(payment_gateway.id, form).then(response => {
        this.$snotify.success("Payment Gateway updated")
        this.getAll()
      }).catch(() => {}).finally(() => this.isBusy = false);
    },
  }
}
</script>

<style scoped>

</style>

<template>
  <v-app>
    <v-card flat>
      <v-card-text>
        <div class="row">
          <div class="col-12 float-right">
            <h3>Tax Setting</h3>
          </div>
          <div class="col-12 form-group">
            <v-text-field outlined dense v-model="setting.tax_percentage" label="Tax Percentage"></v-text-field>
          </div>
          <div class="col-12 form-group">
            <v-text-field outlined dense v-model="setting.vat_no" label="Vat No."></v-text-field>
          </div>
          <div class="col-12 form-group">
            <v-text-field outlined dense v-model="setting.pan_no" label="Pan No."></v-text-field>
          </div>
          <div class="col-12 form-group">
            <v-text-field outlined dense v-model="setting.company_name" label="Company Name"></v-text-field>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
export default {
  name: "Tax",
  props: ['setting']
}
</script>

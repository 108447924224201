import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'
const apiService = Apiservice;


export default class PaymentGatewaySettingService {

    #api = null;

    constructor() {
        this.#api = `${API_URL}admin/payment/gateway/setting`;
    }

    paginate() {
        let url = `${this.#api}`;
        return apiService.get(url);
    }

    all() {
        let url = `${this.#api}`;
        return apiService.get(url);
    }

    update(id, data) {
        let url = `${this.#api}/${id}`
        return apiService.post(url, data);

    }

    store(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);
    }

    findByType(type) {
        let url = `${this.#api}/${type}`
        return apiService.get(url);
    }

    delete(id) {
        let url = `${this.#api}/${id}`
        return apiService.delete(url);
    }
}

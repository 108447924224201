<template>
  <v-card flat>
    <v-card-text>
      <div class="row">
        <div class="col-12 float-right">
          <h3>Appointment Setting</h3>
          <div>
            <div class="col-12 float-right">
              <v-text-field 
                type="number" 
                outlined 
                dense 
                v-model="setting.appointment_session_time"
                label="Appointment Session Time"
              >
              </v-text-field>
            </div>
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  props: ["setting"],
  name: "appointment",
}
</script>

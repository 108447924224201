<template>
    <v-app class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <div class="card-title">
                        <div class="row">
                            <div class="col-6">
                                <h4>
                                    Settings
                                </h4>
                                <router-link :to="{ name: 'dashboard' }">
                                    Dashboard
                                </router-link>
                                \
                                Settings
                            </div>
                            <div class="col-6 float-right" >
                                <v-btn v-if="checkIsAccessible('site-setting', 'edit') "
                                        :disabled="sizeExceed"
                                        :loading="isBusy"
                                        @click="saveSetting"
                                        class="text-white float-right btn btn-primary"
                                        depressed
                                >Save settings
                                </v-btn>
                            </div>
                            <div class="col-12">
                                <v-tabs
                                        class="justify-start"
                                        v-model="tab"
                                        vertical
                                >
                                    <v-tabs-slider color="yellow"></v-tabs-slider>

                                    <v-tab class="justify-start">
                                        Global
                                    </v-tab>

                                    <v-tab class="justify-start">
                                        Social media
                                    </v-tab>

                                    <v-tab class="justify-start">
                                        Tax
                                    </v-tab>

                                    <v-tab class="justify-start">
                                        Widget & API
                                    </v-tab>

                                    <v-tab class="justify-start">
                                        SMTP
                                    </v-tab>
                                    <v-tab class="justify-start">
                                        Storage
                                    </v-tab>

                                    <v-tab class="justify-start">
                                        Theme
                                    </v-tab>

                                    <v-tab class="justify-start">
                                        Appointment
                                    </v-tab>

                                    <v-tab class="justify-start">
                                        Payment
                                    </v-tab>

                                    <v-tab-item>
                                        <v-card flat>
                                            <v-card-text>
                                                <v-tabs class="justify-start" vertical>
                                                    <v-tab class="justify-start">
                                                        General
                                                    </v-tab>
                                                    <v-tab class="justify-start">
                                                        Logo & Favicon
                                                    </v-tab>
                                                    <v-tab class="justify-start">
                                                        Contact
                                                    </v-tab>

                                                    <v-tab class="justify-start">
                                                        Action Button
                                                    </v-tab>

                                                    <v-tab-item>
                                                        <v-card flat>
                                                            <v-card-text>
                                                                <div class="row">
                                                                    <div class="col-6 form-group">
                                                                        <v-text-field dense label="Slogon"
                                                                                      outlined
                                                                                      v-model="setting.slogan"></v-text-field>
                                                                    </div>

                                                                    <div class="col-6 form-group">
                                                                        <v-text-field dense label="Website Tagline"
                                                                                      outlined
                                                                                      v-model="setting.tagline"></v-text-field>
                                                                    </div>
                                                                    <div class="col-6 form-group">
                                                                        <v-text-field dense label="Website"
                                                                                      outlined
                                                                                      v-model="setting.website"></v-text-field>
                                                                    </div>

                                                                    <div class="col-6 form-group">
                                                                        <v-text-field dense
                                                                                      label="Copyright Text" outlined
                                                                                      v-model="setting.copy_right_text"></v-text-field>
                                                                    </div>

                                                                    <div class="col-6 form-group">
                                                                        <v-text-field
                                                                                dense
                                                                                label="Default Date Format"
                                                                                outlined
                                                                                v-model="setting.date_format">
                                                                        </v-text-field>
                                                                        <!-- <span v-if="$v.setting.phone.$error" academic-class="text-danger">Website Title is required.</span> -->
                                                                    </div>
                                                                  <div class="col-6 form-group">
                                                                    <v-select
                                                                        :items="['free-text', 'address-form']"
                                                                        dense
                                                                        label="User Address Type"
                                                                        outlined
                                                                        v-model="setting.address_type">
                                                                    </v-select>
                                                                    <!-- <span v-if="$v.setting.phone.$error" academic-class="text-danger">Website Title is required.</span> -->
                                                                  </div>

                                                                    <v-col cols="12" md="12">
                                                                        <v-text-field
                                                                            label="Agent commission code"
                                                                            v-model="setting.agent_commission_code"
                                                                            outlined
                                                                            dense
                                                                        ></v-text-field>
                                                                    </v-col>

                                                                    <v-col cols="12" md="12">
                                                                        Agent commission note
                                                                        <ckeditor
                                                                            :config="editorConfig1"
                                                                            v-model="setting.agent_commission_note"
                                                                        >
                                                                        </ckeditor>
                                                                    </v-col>

                                                                    <v-col cols="12" md="12">
                                                                        <v-switch label="Enable Cookies" v-model="setting.enable_cookies"></v-switch>
                                                                    </v-col>

                                                                    <v-col cols="12" md="12" v-if="setting.enable_cookies">
                                                                        Cookie Consent Text
                                                                        <ckeditor
                                                                                :config="editorConfig"
                                                                                v-model="setting.cookie_consent_text"
                                                                        >
                                                                        </ckeditor>

                                                                    </v-col>

                                                                    <v-col cols="12" md="12">
                                                                        Application Terms & Condition <span class="text-danger">*</span>
                                                                        <ckeditor
                                                                                :config="editorConfig2"
                                                                                v-model="setting.terms_condition"
                                                                        >
                                                                        </ckeditor>

                                                                    </v-col>
                                                                </div>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-tab-item>

                                                    <v-tab-item>
                                                        <v-card flat>
                                                            <v-card-text>
                                                                <div class="row">
                                                                    <div class="col-12 form-group">
                                                                        <v-file-input @change="checkSize($event)" dense
                                                                                      label="Logo"
                                                                                      outlined
                                                                                      v-model="setting.logo"></v-file-input>

                                                                        <img
                                                                                :src="setting.logo_path['real']"
                                                                                alt
                                                                                class="mt-5"
                                                                                style="height:100px"
                                                                                v-if="setting.logo_path && setting.logo_path['real']"
                                                                        />
                                                                    </div>
                                                                    <div class="col-12 form-group">
                                                                        <v-file-input @change="checkSize($event)" dense
                                                                                      label="Admin Logo"
                                                                                      outlined
                                                                                      v-model="setting.app_logo"></v-file-input>

                                                                        <img
                                                                                :src="setting.app_logo_path['real']"
                                                                                alt
                                                                                class="mt-5"
                                                                                style="height:100px"
                                                                                v-if="setting.app_logo_path && setting.app_logo_path['real']"
                                                                        />
                                                                    </div>
                                                                  <div class="col-12 form-group">
                                                                        <v-file-input @change="checkSize($event)" dense
                                                                                      label="Footer Logo"
                                                                                      outlined
                                                                                      v-model="setting.footer_logo"></v-file-input>

                                                                        <img
                                                                                :src="setting.footer_logo_path['real']"
                                                                                alt
                                                                                class="mt-5"
                                                                                style="height:100px"
                                                                                v-if="setting.footer_logo_path && setting.footer_logo_path['real']"
                                                                        />
                                                                    </div>


                                                                    <div class="col-12 form-group">
                                                                        <v-file-input @change="checkSize($event)" dense
                                                                                      label="Favicon"
                                                                                      outlined
                                                                                      v-model="setting.fav_icon"></v-file-input>
                                                                        <img
                                                                                :src="setting.fav_icon_path['real']"

                                                                                alt
                                                                                class="mt-5"
                                                                                style="height:100px"
                                                                                v-if="setting.fav_icon_path && setting.fav_icon_path['real']"
                                                                        />
                                                                    </div>
                                                                    <span class="text-danger" v-if="sizeExceed">File Size 20MB Max Allowed</span>
                                                                </div>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-tab-item>

                                                    <v-tab-item>
                                                        <v-card flat>
                                                            <v-card-text>
                                                                <div class="row">
                                                                    <div class="col-12 form-group">
                                                                        <v-text-field dense label="Phone"
                                                                                      outlined
                                                                                      v-model="setting.phone"></v-text-field>
                                                                        <span class="text-danger"
                                                                              v-if="$v.setting.phone.$error">Enter valid phone no</span>
                                                                    </div>
                                                                    <div class="col-12 form-group">
                                                                        <v-text-field dense label="Email"
                                                                                      outlined
                                                                                      v-model="setting.email"></v-text-field>
                                                                        <span class="text-danger"
                                                                              v-if="$v.setting.email.$error">Enter valid Email</span>
                                                                    </div>
                                                                    <div class="col-12 form-group">
                                                                        <v-text-field dense label="Address"
                                                                                      outlined
                                                                                      v-model="setting.address"></v-text-field>
                                                                        <!-- <span v-if="$v.setting.address.$error" academic-class="text-danger">Address is required.</span> -->
                                                                    </div>
                                                                    <div class="col-12 form-group">
                                                                        <v-textarea dense label="Google Map Embed URL"
                                                                                      outlined
                                                                                      v-model="setting.map_url"></v-textarea>

                                                                    </div>

                                                                </div>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-tab-item>

                                                    <v-tab-item>
                                                        <v-card flat>
                                                            <v-card-text>
                                                                <div class="row">
                                                                    <div class="col-12 form-group">
                                                                        <v-text-field
                                                                                dense
                                                                                label="Action Button Text" outlined
                                                                                v-model="setting.action_button_text"></v-text-field>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12 form-group">
                                                                        <v-text-field
                                                                                dense
                                                                                label="Action Button Link" outlined
                                                                                v-model="setting.action_button_link"></v-text-field>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-12 form-group">
                                                                        <v-select
                                                                                :items="['yes','no']"
                                                                                dense
                                                                                label="Open in New Tab"
                                                                                outlined
                                                                                v-model="setting.action_button_link_action"
                                                                        ></v-select>
                                                                    </div>
                                                                </div>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-tab-item>

                                                </v-tabs>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>

                                    <v-tab-item>
                                        <v-card flat>
                                            <v-card-text>
                                                <div class="row">
                                                    <div class="col-12 float-right">
                                                        <h3>Social Media</h3>
                                                    </div>
                                                    <div class="col-6 form-group">
                                                        <v-text-field dense label="Facebook" outlined
                                                                      v-model="setting.facebook"></v-text-field>
                                                        <span class="text-danger" v-if="$v.setting.facebook.$error">Not a valid url</span>
                                                    </div>
                                                    <div class="col-6 form-group">
                                                        <v-text-field dense label="Twitter" outlined
                                                                      v-model="setting.twitter"></v-text-field>
                                                        <span class="text-danger" v-if="$v.setting.twitter.$error">Not a valid url</span>
                                                    </div>
                                                    <div class="col-6 form-group">
                                                        <v-text-field dense label="Youtube" outlined
                                                                      v-model="setting.youtube"></v-text-field>
                                                        <span class="text-danger" v-if="$v.setting.youtube.$error">Not a valid url</span>
                                                    </div>
                                                    <div class="col-6 form-group">
                                                        <v-text-field dense label="Instagram" outlined
                                                                      v-model="setting.instagram"></v-text-field>
                                                        <span class="text-danger" v-if="$v.setting.instagram.$error">Not a valid url</span>
                                                    </div>

                                                    <div class="col-6 form-group">
                                                        <v-text-field dense label="LinkedIn" outlined
                                                                      v-model="setting.linkedin"></v-text-field>
                                                        <span class="text-danger" v-if="$v.setting.linkedin.$error">Not a valid url</span>
                                                    </div>
                                                    <div class="col-6 form-group">
                                                        <v-text-field dense label="Tiktok" outlined
                                                                      v-model="setting.tiktok"></v-text-field>
                                                        <span class="text-danger" v-if="$v.setting.tiktok.$error">Not a valid url</span>
                                                    </div>
                                                    <div class="col-6 form-group">
                                                        <v-text-field dense label="Pininterest" outlined
                                                                      v-model="setting.pininterest"></v-text-field>
                                                        <span class="text-danger" v-if="$v.setting.pininterest.$error">Not a valid url</span>
                                                    </div>
                                                    <div class="col-6 form-group">
                                                        <v-text-field dense label="Viber" outlined
                                                                      v-model="setting.viber"></v-text-field>
                                                        <span class="text-danger" v-if="$v.setting.viber.$error">Not a valid nnumber</span>
                                                    </div>
                                                    <div class="col-6 form-group">
                                                        <v-text-field dense label="Whatsapp" outlined
                                                                      v-model="setting.whatsapp"></v-text-field>
                                                        <span class="text-danger" v-if="$v.setting.whatsapp.$error">Not a valid nnumber</span>
                                                    </div>


                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-tab-item>

                                     <v-tab-item>
                                        <tax :setting="setting" @update_setting="updateSetting"></tax>
                                    </v-tab-item>

                                     <v-tab-item>
                                        <widget :facebook_chat_widgets="facebook_chat_widgets"
                                                :setting="setting"
                                                @update_setting="updateSetting"></widget>
                                    </v-tab-item>

                                    <v-tab-item>
                                        <smtp-setting :setting="setting" @update_setting="updateSetting"></smtp-setting>
                                    </v-tab-item>

                                    <v-tab-item>
                                      <storage :setting="setting" @update_setting="updateSetting"></storage>
                                    </v-tab-item>

                                    <v-tab-item>
                                        <college-setting :colors_variables="colors_variables"
                                                         :setting="setting"
                                                         @reset_theme="resetTheme"
                                        ></college-setting>
                                    </v-tab-item>

                                    <v-tab-item>
                                          <appointment :setting="setting">

                                          </appointment>
                                    </v-tab-item>
                                  <v-tab-item>
                                          <payment-gateway :setting="setting">

                                          </payment-gateway>
                                    </v-tab-item>


                                </v-tabs>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    </v-app>
</template>
<script>
    import SiteSettingService from "@/services/cms/site-setting/SiteSettingService";
    import {email, minLength, numeric, url} from "vuelidate/lib/validators";
    import Swal from "sweetalert2";
    import Widget from "./Widget";
    import CollegeSetting from "./CollegeSetting";
    import SmtpSetting from "./Smtp";
    import Appointment from "./Appointment";
    import PaymentGateway from "@/view/pages/view/cms/setting/PaymentGateway";
    import Storage from "./Storage";
    import Tax from "@/view/pages/view/cms/setting/Tax.vue";

    const siteSettingService = new SiteSettingService();
    import UserService from "@/core/services/user/UserService";
    const userService = new UserService();
    export default {
        name: "setting",
        validations: {
            setting: {
                phone: {minLength: minLength(10)},
                email: {email},
                whatsapp: {numeric, minLength: minLength(10)},
                viber: {numeric, minLength: minLength(10)},
                pininterest: {url},
                tiktok: {url},
                linkedin: {url},
                instagram: {url},
                youtube: {url},
                twitter: {url},
                facebook: {url},
                zoom_link: {url},
            }
        },
        components: {
            Widget,
            CollegeSetting,
            SmtpSetting,
            Appointment,
            PaymentGateway,
            Storage,
            Tax,
        },
        data() {
            return {
                tab: null,
                file1: null,
                button: null,
                file2: null,
                edit: false,
                isReload: false,
                isBusy: false,
                sizeExceed: false,
                academicYears: [],
                users: [],
                searchName: null,
                searchDirector: null,
                facebook_chat_widgets: {},
                editorConfig: {
                  versionCheck: false,
                  toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ]
                },
                editorConfig1: {
                  versionCheck: false,
                  toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ]
                },
                editorConfig2: {
                  versionCheck: false,
                  toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ]
                },
                colors_variables: {
                    login: {
                        primary_color: '#00458f',
                        primary_text_color: '#fff',
                        secondary_color: '#f2883a',
                        secondary_text_color: '#fff',
                    },
                    header: {
                        primary_color: "#5d5d5d",
                        primary_text_color: "#ffffff",
                        secondary_color: "#f2883a",
                        secondary_text_color: "#ffffff",

                    },
                    side_bar: {
                        bg_color: "#000000",
                        primary_color: "#000000",
                        primary_text_color: "#fff",
                        secondary_color: "#ffffff",
                        secondary_text_color: "#fff"

                    },
                    button: {
                        primary_color: "#00458f",
                        primary_text_color: "#ffffff",
                        secondary_color: "#16b",
                        secondary_text_color: "#ffffff",
                        pagination_color: "#00458f",
                    },
                    email: {
                      header_background_color: "#00458f",
                      header_text_color: "#00458f",
                      footer_background_color: "#00458f",
                      footer_text_color: "#00458f",
                    }
                },
                search:{
                    name:null,
                },
                setting: {
                    phone: null,
                    email: null,
                    website: null,
                    address: null,
                    whatsapp: null,
                    viber: null,
                    tax_percentage: null,
                    pininterest: null,
                    tiktok: null,
                    linkedin: null,
                    instagram: null,
                    youtube: null,
                    twitter: null,
                    facebook: null,
                    college_name: null,
                    academic_year_id: null,
                    copy_right_text: null,
                    cookie_consent_text: `This website uses cookies to ensure you get the best experience on our website`,
                    zoom_link: null,
                    map_url: null,
                    storage_type: 'local',
                    storage_access_key: null,
                    storage_secret_key: null,
                    storage_region: null,
                    storage_bucket_name: null,
                    storage_url: null,
                    slogan: null,
                    tagline: null,
                    logo: null,
                    agent_commission_code: null,
                    agent_commission_note: null,
                    email_logo_image_file: null,
                    fav_icon: null,
                    action_button_text: null,
                    action_button_link: null,
                    action_button_link_action: null
                }
            };
        },
        mounted() {
            this.getSetting();
            this.colorSetting();
        },
        watch: {

        },
        methods: {
            getSetting() {
                siteSettingService.show().then(response => {
                    this.setting = response.data;
                    if (this.setting && this.setting.id) {
                        this.edit = true;
                        if (this.setting.facebook_chat_widgets)
                            this.facebook_chat_widgets = JSON.parse(this.setting.facebook_chat_widgets);
                        if (this.setting.colors_variables) {
                            this.colors_variables = JSON.parse(this.setting.colors_variables);
                            if(this.colors_variables.email == "undefined" || this.colors_variables.email == undefined) {
                              this.colors_variables.email = {
                                header_background_color: "#00458f",
                                header_text_color: "#00458f",
                                footer_background_color: "#00458f",
                                footer_text_color: "#00458f",
                              }
                            }
                            this.setStyleVariable();
                        }
                        if (this.setting.login_bg_image)
                            delete this.setting.login_bg_image
                        if (this.setting.logo)
                            delete this.setting.logo
                        if (this.setting.fav_icon)
                            delete this.setting.fav_icon
                        if (this.setting.app_logo)
                            delete this.setting.app_logo

                    }
                });
            },

            colorSetting() {
                siteSettingService.getColorsVariables().then(response => {});
            },

            saveSetting() {
                this.$v.setting.$touch();
                if (this.$v.setting.$error) {
                    setTimeout(() => {
                        this.$v.setting.$reset();
                    }, 3000);
                } else {
                    if (this.edit) {
                        this.setting.facebook_chat_widgets = JSON.stringify(this.facebook_chat_widgets);
                        this.setting.colors_variables = this.colors_variables ? JSON.stringify(this.colors_variables) : null;
                        this.updateSetting();
                    } else {
                        this.createSetting();
                    }
                }
            },
            setStyleVariable() {
                document.documentElement.style.setProperty('--sidebar-secondary-color', this.colors_variables.side_bar.secondary_color);
                document.documentElement.style.setProperty('--sidebar-primary-text-color', this.colors_variables.side_bar.primary_text_color);
                document.documentElement.style.setProperty('--sidebar-secondary-text-color', this.colors_variables.side_bar.secondary_text_color);

                document.documentElement.style.setProperty('--header-primary-color', this.colors_variables.header.primary_color);
                document.documentElement.style.setProperty('--header-secondary-color', this.colors_variables.header.secondary_color);
                document.documentElement.style.setProperty('--header-primary-text-color', this.colors_variables.header.primary_text_color);
                document.documentElement.style.setProperty('--header-secondary-text-color', this.colors_variables.header.secondary_text_color);


                document.documentElement.style.setProperty('--button-primary-color', this.colors_variables.button.primary_color);
                document.documentElement.style.setProperty('--button-secondary-color', this.colors_variables.button.secondary_color);
                document.documentElement.style.setProperty('--button-primary-text-color', this.colors_variables.button.primary_text_color);
                document.documentElement.style.setProperty('--button-secondary-text-color', this.colors_variables.button.secondary_text_color);
                document.documentElement.style.setProperty('--button-pagination_color', this.colors_variables.button.pagination_color);

            },
            resetTheme() {
                this.colors_variables = null;
                this.isReload = true;
                this.saveSetting();
            },

            checkSize(file) {
                let size = file.target.files[0].size / 1024 / 1024;
                let mbSize = size.toFixed(2);
                if (mbSize > 20) {
                    this.sizeExceed = true;
                } else {
                    this.sizeExceed = false;
                }
                // console.log(mbSize)
            },
            createSetting() {
                this.isBusy = true;
                let fd = this.generateFd();
                siteSettingService.store(fd).then(response => {
                    setTimeout(() => {
                      this.isBusy = false;
                      this.$bus.emit('initSetting');
                      this.$snotify.success("updated successfully");
                      this.getSetting();
                    }, 2000);
                }).catch(() => {
                  this.isBusy = false
                });
            },
            updateSetting() {
                this.isBusy = true;
                let fd = this.generateFd();
                siteSettingService.update(this.setting.id, fd).then(response => {
                    setTimeout(() => {
                      this.isBusy = false;
                      this.$snotify.success("updated successfully");
                      if (this.isReload) {
                        location.reload()
                      } else {
                        this.$bus.emit('initSetting');
                        this.resetForm();
                      }
                    }, 2000);
                }).catch(() => {
                  this.isBusy = false
                });
            },
            resetForm() {
                this.setting = {
                    phone: null,
                    email: null,
                    website: null,
                    address: null,
                    whatsapp: null,
                    viber: null,
                    pininterest: null,
                    tiktok: null,
                    linkedin: null,
                    instagram: null,
                    youtube: null,
                    twitter: null,
                    facebook: null,
                    copy_right_text: null,
                    cookie_consent_text: `This website uses cookies to ensure you get the best experience on our website`,
                    zoom_link: null,
                    map_url: null,
                    tagline: null,
                    logo: null,
                    email_logo_image_file: null,
                    fav_icon: null
                };
                this.getSetting();
            },
            generateFd() {
                let fd = new FormData();
                for (let key in this.setting) {
                    if (
                        key == "logo" &&
                        this.setting["logo"] &&
                        this.setting["logo"] != null
                    ) {
                        fd.append("logo", this.setting[key]);
                    } else if (
                        key == "fav_icon" &&
                        this.setting["fav_icon"] &&
                        this.setting["fav_icon"] != null
                    ) {
                        fd.append("fav_icon", this.setting[key]);
                    } else if (
                        key == "footer_logo" &&
                        this.setting["footer_logo"] &&
                        this.setting["footer_logo"] != null
                    ) {
                        fd.append("footer_logo", this.setting[key]);
                    } else if (key == "enable_level" && this.setting["enable_level"]) {
                        fd.append("enable_level", this.setting['enable_level'] ? 1 : 0);
                    } else if (key == "enable_buildings" && this.setting["enable_buildings"]) {
                        fd.append("enable_buildings", this.setting['enable_buildings'] ? 1 : 0);
                    } else {
                        fd.append(key, this.setting[key] ? this.setting[key] : "");
                    }
                }
                return fd;
            },

        }
    };
</script>
